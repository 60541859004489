var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.modelId)?_c('BaseAlert',{attrs:{"type":"info","text":"Перед добавлением изображений к сущности сохраните ее"}}):_c('div',{staticClass:"content-images pl-1"},[(_vm.defer(1) && !_vm.disabled)?_c('div',{staticClass:"text-subtitle-1 pa-1"},[_vm._v(" Максимальный размер одной фотографии "),_c('span',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm.bytesToSize(_vm.maxImageSize, ' ')))])]):_vm._e(),(_vm.defer(1))?_c('BaseUploaderFile',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disabled),expression:"!disabled"}],staticClass:"pa-1",attrs:{"single-line":"","accept-types":"image/png, image/jpeg, image/jpg","disabled":_vm.isLoading,"title":"Загрузить фото","multiple":"","id":"images"},on:{"load":_vm.uploadPhoto}}):_vm._e(),(_vm.isLoading)?_c('v-progress-circular',{staticClass:"mb-3",class:{'px-0': _vm.$vuetify.breakpoint.xsOnly },staticStyle:{"width":"100%"},attrs:{"size":50,"color":"primary","indeterminate":""}}):_vm._e(),(_vm.photos.length === 0 && !_vm.isLoading)?_c('NoDocs'):_vm._e(),_c('FancyBox',{attrs:{"options":{
         Carousel: {
         infinite: false
        },
         Thumbs: {
         showOnStart: false
        }
      }}},[_c('div',{staticClass:"d-flex flex-wrap images pl-1"},_vm._l((_vm.photos),function(item){return (_vm.photos.length > 0 && _vm.defer(2))?_c('Card',{key:item.id,staticClass:"pa-2 d-flex flex-column",attrs:{"max-width":"250","max-height":_vm.heightCard},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"pl-1 pt-1 d-flex justify-space-between flex-row align-top",staticStyle:{"width":"100%"}},[_c('p',{staticClass:"ma-0 small-text"},[_vm._v("Создано: "+_vm._s(_vm.timestampToDate(item.image.created_at)))]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disabled),expression:"!disabled"}],staticClass:"ml-auto",attrs:{"disabled":_vm.isLoading,"color":"red"},on:{"click":function($event){return _vm.handleDelete(item.image.id)}}},[_vm._v("mdi-delete-outline ")])],1),_c('a',{attrs:{"data-fancybox":"gallery","data-src":_vm.getFullImageUrl(item.image.path, item.image.file_name),"data-caption":item.image.description}},[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"max-height":"100","max-width":"250","min-height":"150","contain":"","src":_vm.getThumbnailUrl(item.image.path, item.image.file_name),"lazy-src":require("@/assets/gray.png")}})],1),(_vm.withDescription)?_c('div',{staticClass:"d-flex flex-row align-center justify-center mt-2 mb-1 description-container"},[_c('BaseBtn',{staticClass:"mr-1",attrs:{"is-type-icon":"","color":"#64C7FF","icon":item.isEditModeDescription ? 'mdi-content-save' : 'mdi-pencil-outline',"disabled":_vm.disabled},on:{"click":function($event){return _vm.handleEditDescr(item)}}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('p',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!item.isEditModeDescription),expression:"!item.isEditModeDescription"}],staticClass:"mb-1 mr-2 description-image",on:{"click":function($event){return _vm.handleEditDescr(item)}}},on),[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.localDescr(item.image),25, '...'))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.localDescr(item.image))+" ")]),_c('v-textarea',{directives:[{name:"show",rawName:"v-show",value:(item.isEditModeDescription),expression:"item.isEditModeDescription"}],attrs:{"outlined":"","placeholder":"Описание","dense":"","no-resize":"","hide-details":"","height":"45"},model:{value:(item.image.description),callback:function ($$v) {_vm.$set(item.image, "description", $$v)},expression:"item.image.description"}})],1):_vm._e()]},proxy:true}],null,true)}):_vm._e()}),1)]),_c('BaseDialog',{attrs:{"role":"delete","label":"Действительно удалить изображение?","max-width":350,"is-confirm-btn":"","is-cancel-btn":""},on:{"confirm":_vm.deletePhoto},model:{value:(_vm.isDeleteDialog),callback:function ($$v) {_vm.isDeleteDialog=$$v},expression:"isDeleteDialog"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }