<template>
  <div>
    <v-file-input
      :id="id"
      class="d-none"
      :multiple="multiple"
      :accept="acceptTypes"
      :single-line="singleLine"
      @change="$emit('load', $event)"
      :disabled="disabled"
    >
    </v-file-input>

    <label
      :for="id"
      class="loadFile"
      :style="stylesLabel"
    >
      <v-input
        :disabled="disabled"
        hide-details
        class="v-btn v-btn--is-elevated v-btn--has-bg v-size--small align-center mb-3 primary pt-4 pb-4 font-weight-bold"
      >
        <v-icon color="white" left>mdi-download</v-icon>
        {{title}}
      </v-input>
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseUploaderFile',
  props: {
    disabled: Boolean,
    acceptTypes: String,
    singleLine: Boolean,
    multiple: {
      type: Boolean,
      default: true
    },
    title: String,
    id: {
      type: String,
      required: true
    },
    stylesLabel: String
  }
}
</script>

<style scoped>
.loadFile {
  display: block;
  width: 165px;
}
</style>