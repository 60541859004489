export default { //note: вынести потом в папку и отрефакторить остальные файлы по проекту
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true,
      immediate: true
    },
  }
}