<template>
  <div
    style="height: 50%"
    class="d-flex justify-center align-center flex-column"
  >
    <v-icon
      size="100"
      color="#abb6c4"
    >mdi-file-hidden
    </v-icon>
    <div class="grey--text">{{ $t('message.noDocs') }}</div>
  </div>
</template>

<script>
export default {
  name: 'NoDocs',
  props: {
    icon: {
      type: String,
      default: 'mdi-file-hidden'
    }
  }
}
</script>