export default {
  data () {
    return {
      displayPriority: 0,
      count: 10,
      isLoading: false
    }
  },
  mounted () {
    this.runDisplayPriority()
  },
  methods: {
    runDisplayPriority () {
      const step = () => {
        requestAnimationFrame(() => {
          this.isLoading = true
          this.displayPriority++
          if (this.displayPriority < this.count) {
            step()
          } else {
            this.isLoading = false
          }
        })
      }
      step()

    },
    defer (priority) {
      return this.displayPriority >= priority
    }
  }
}
