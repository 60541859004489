<template>
  <div>
    <BaseAlert
      v-if="!modelId"
      type="info"
      text="Перед добавлением изображений к сущности сохраните ее"
    />
    <div
      v-else
      class="content-images pl-1"
    >
      <div
        class="text-subtitle-1 pa-1"
        v-if="defer(1) && !disabled"
      >
        Максимальный размер одной фотографии <span class="blue--text">{{ bytesToSize(maxImageSize, ' ') }}</span>
      </div>

      <BaseUploaderFile
        v-if="defer(1)"
        class="pa-1"
        v-show="!disabled"
        single-line
        accept-types="image/png, image/jpeg, image/jpg"
        :disabled="isLoading"
        @load="uploadPhoto"
        title="Загрузить фото"
        multiple
        id="images"
      />
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        v-if="isLoading"
        style="width: 100%"
        class="mb-3"
        :class="{'px-0': $vuetify.breakpoint.xsOnly }"
      ></v-progress-circular>
      <NoDocs v-if="photos.length === 0 && !isLoading"/>
      <FancyBox
        :options="{
           Carousel: {
           infinite: false
          },
           Thumbs: {
           showOnStart: false
          }
        }"
      >
        <div class="d-flex flex-wrap images pl-1">
          <Card
            v-if="photos.length > 0 && defer(2)"
            v-for="item in photos"
            :key="item.id"
            max-width="250"
            :max-height="heightCard"
            class="pa-2 d-flex flex-column"
          >
            <template #content>
              <div class="pl-1 pt-1 d-flex justify-space-between flex-row align-top" style="width: 100%">
                <p class="ma-0 small-text">Создано: {{ timestampToDate(item.image.created_at) }}</p>
                <v-icon
                  class="ml-auto"
                  @click="handleDelete(item.image.id)"
                  v-show="!disabled"
                  :disabled="isLoading"
                  color="red"
                >mdi-delete-outline
                </v-icon>
              </div>
              <a
                data-fancybox="gallery"
                :data-src="getFullImageUrl(item.image.path, item.image.file_name)"
                :data-caption="item.image.description"
              >
                <v-img
                  max-height="100"
                  max-width="250"
                  min-height="150"
                  contain
                  style="cursor: pointer"
                  :src="getThumbnailUrl(item.image.path, item.image.file_name)"
                  lazy-src="@/assets/gray.png"
                ></v-img>
              </a>
              <div
                class="d-flex flex-row align-center justify-center mt-2 mb-1 description-container"
                v-if="withDescription"
              >
                <BaseBtn
                  is-type-icon
                  color="#64C7FF"
                  :icon="item.isEditModeDescription ? 'mdi-content-save' : 'mdi-pencil-outline'"
                  class="mr-1"
                  @click="handleEditDescr(item)"
                  :disabled="disabled"
                />
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <p
                      @click="handleEditDescr(item)"
                      v-on="on"
                      v-show="!item.isEditModeDescription"
                      class="mb-1 mr-2 description-image"
                    >
                      {{ localDescr(item.image) | truncate(25, '...') }}
                    </p>
                  </template>
                  {{localDescr(item.image)}}
                </v-tooltip>

                <v-textarea
                  outlined
                  placeholder="Описание"
                  v-model="item.image.description"
                  dense
                  no-resize
                  hide-details
                  v-show="item.isEditModeDescription"
                  height="45"
                >
                </v-textarea>
              </div>
            </template>
          </Card>
        </div>
      </FancyBox>
      <BaseDialog
        role="delete"
        label="Действительно удалить изображение?"
        v-model="isDeleteDialog"
        @confirm="deletePhoto"
        :max-width="350"
        is-confirm-btn
        is-cancel-btn
      />
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import BaseDialog from '@/components/base/BaseDialog'
import defer from '@/components/mixins/defer'
import { baseUrl, maxImageSize } from '@/config/common'
import formatters from '@/components/mixins/formatters'
import BaseAlert from '@/components/base/UI/BaseAlert'
import BaseUploaderFile from '@/components/base/BaseUploaderFile'
import { cleanClone } from '@/components/utils/common'
import watchValue from '@/components/mixins/watchValue'
import BaseBtn from '@/components/base/UI/BaseBtn'
import FancyBox from '@/components/base/FancyBox'
import getPath from '@/components/mixins/getPath'
import NoDocs from '@/components/base/BaseDocuments/NoDocs'
import Card from '@/components/base/UI/Card'

//note: компонент переделан. Теперь @input будет возвращать целиком детали. обязательно нужно передать nameObjectImg('passportImages' или 'objectImages')
export default {
  name: 'BasePhoto',
  components: { Card, NoDocs, BaseBtn, BaseUploaderFile, BaseAlert, BaseDialog, BaseHeader, FancyBox },
  mixins: [defer, formatters, watchValue, getPath],
  props: {
    modelName: {
      type: String,
      required: true
    },
    modelId: {
      type: Number,
      required: false
    },
    disabled: { Boolean, default: false }, //пропс передается из PassportObjectDetail, идет проверка роли "auditor",
    value: Object,
    nameObjectImg: String,
    withDescription: Boolean
  },
  data () {
    return {
      detail: this.value,
      baseUrl,
      isOpenDialog: false,
      isDeleteDialog: false,
      isLoading: false,
      activeImage: {},
      uploadFile: [],
      photos: this.value?.properties[this.nameObjectImg] || [],
      deleteId: null,
      maxImageSize
    }
  },
  computed: {
    heightCard () {
      let defaultHeight = 250
      if (this.withDescription) {
        defaultHeight += 100
      }
      return defaultHeight
    }
  },
  methods: {
    async uploadPhoto (uploadFile) {
      if (uploadFile.length > 0 && this.validationPhoto(uploadFile)) {
        this.isLoading = true
        const formData = new FormData()
        for (let i = 0; i < uploadFile.length; i++) {
          formData.append(`files[${i}]`, uploadFile[i])
        }
        formData.append('modelName', this.modelName)
        formData.append('modelId', String(this.modelId))
        const response = await this.$store.dispatch('api/post', { url: '/image', data: formData }, { root: true })
        if (response.success) {
          this.photos = this.photos.concat(this.addEditModeAttr(response.data))
          this.$set(this.detail.properties, this.nameObjectImg, this.photos)
          await this.$store.dispatch('systemMessages/success', this.$t('message.successLoadPhoto'), { root: true })
        }
        this.$emit('input', cleanClone(this.detail))
        this.isLoading = false
      } else if (uploadFile) {
        const response = await this.$store.dispatch('api/post', {
          url: '/image',
          data: { data: uploadFile }
        }, { root: true })
        if (response.success) {
          await this.$store.dispatch('systemMessages/success', 'Описание добавлено', { root: true })
        }
      }
    },
    handleEditDescr (File) {
      if (File.isEditModeDescription) {
        File.image.description_date = new Date().toLocaleString('ru-RU', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
        this.uploadPhoto(File.image)
        File.isEditModeDescription = !File.isEditModeDescription
      } else {
        File.isEditModeDescription = !File.isEditModeDescription
      }
    },
    localDescr (item) {
      return item.description ? item.description : 'Добавить описание'
    },
    validationPhoto (value) {
      let isError = true
      if (value) {
        value.map((item) => {
          if (item?.size > maxImageSize) {
            isError = false
            this.$store.dispatch(
              'systemMessages/error',
              `Размер фото должен быть не больше ${this.bytesToSize(maxImageSize, ' ')}: ` + item.name,
              { root: true }
            )
          }
        })
      } else {
        isError = false
      }
      return isError
    },
    async deletePhoto () {
      this.isLoading = true
      const response = await this.$store.dispatch('api/delete', { url: '/image?id=' + this.deleteId }, { root: true })
      if (response.success) {
        this.photos = this.photos.filter((item) => item.image.id !== this.deleteId)
        this.$set(this.detail.properties, this.nameObjectImg, this.photos)
        await this.$store.dispatch('systemMessages/success', this.$t('message.successDeletePhoto'), { root: true })
      }
      this.$emit('input', cleanClone(this.detail))
      this.isLoading = false
      this.isDeleteDialog = false
      this.deleteId = null
    },
    handleDelete (id) {
      this.deleteId = id
      this.isDeleteDialog = true
    },
    addEditModeAttr (array) {
      return array = array.map(item => {
        return {
          ...item,
          isEditModeDescription: false
        }
      })
    }
  },
  mounted () {
    this.photos = this.addEditModeAttr(this.photos)
    this.$nextTick(() => {
      if (!this.value.properties.id) {
        this.$emit('save', cleanClone(this.value))
      }
    })
  }
}
</script>

<style>
  .description-image-textarea textarea {
    max-height: 70px;
    line-clamp: 5; /* максимальное количество отображаемых строк */
    -webkit-line-clamp: 5;
    word-break: break-word;
    overflow: hidden;
    line-height: 1.2;
    text-overflow: ellipsis;
    margin: 8px 0;
    min-width: 180px;
  }
  .content-images {
    height: calc(100vh - 75px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .images {
    gap: 20px 10px;
  }
  .description-image {
    max-height: 76px;
    line-clamp: 5; /* максимальное количество отображаемых строк */
    -webkit-line-clamp: 5;
    word-break: break-word;
    overflow: hidden;
    line-height: 1.2;
    text-overflow: ellipsis;
  }
  @media (max-width: 1500px) {
    .photo-in-dialog > .v-responsive__content {
      width: 700px !important;
    }
  }

</style>
