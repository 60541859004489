<template>
  <div
    :class="className"
    :style="{minWidth: minWidth, minHeight: minHeight}"
    class="card"
  >
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    minWidth: { type: String, default: '256px' },
    minHeight: { type: String, default: '256px' }
  },
  computed: {
    className () {

    }
  }
}
</script>

<style scoped>
  .card {
    -webkit-box-shadow: 0 3px 1px -2px rgba(0, 157, 226, 0.37),
    0 2px 2px 0 rgba(48, 166, 232, 0.37),
    0 1px 5px 0 rgba(0, 157, 226, 0.37);
    -moz-box-shadow: 0 3px 1px -2px rgba(0, 157, 226, 0.37),
    0 2px 2px 0 rgba(48, 166, 232, 0.37),
    0 1px 5px 0 rgba(0, 157, 226, 0.37);
    box-shadow: 0 3px 1px -2px rgba(0, 157, 226, 0.37),
    0 2px 2px 0 rgba(48, 166, 232, 0.37),
    0 1px 5px 0 rgba(0, 157, 226, 0.37);
    border-radius: 3%;
    box-sizing: border-box;
  }
</style>